<template>

  <div v-if="isShow">
    <loading/>
  </div>

<div class="common-bg">
  <div class="middle">
    <h1 class="common-h1">最新消息</h1>
    <div class="in-news  news-list">
      <ul>
        <li @click="details(item.id)"  v-for="item in newsList" :key="item.id">
          <img :src="item.cover">
          <div class="in-news-txt">
            <h4>{{item.title}}</h4>
            <h5>{{ item.date }}</h5>
            <div class="in-news-btn">
              查看详情 <i class="iconfont icon-jiantou"></i>
            </div>
          </div>
        </li>
        <li></li>
      </ul>
    </div>

    <Pagination
        :current-page="currentPage"
        :total-pages="totalPages"
        :show-pagination="showPagination"
        @goto-page="onPageChange"
    />

  </div>
</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Loading from "@/components/loading";
import {getNews} from "@/network/main";

export default {
  name: "News",
  components:{
    Loading,
    Pagination,
  },
  data(){
     return{
       newsList:[],
       currentPage: 1, // 当前页码
       totalPages: 1, // 总页数
       showPagination: true, // 是否显示分页
       isShow:true,
     }
  },
  created() {
    this.getNews(this.currentPage)
  },
  methods:{
    //列表
    getNews(page) {
      getNews(page).then((res) => {
        this.isShow = false;
        this.newsList = res.data.data.data;
        this.totalPages = res.data.data.last_page;
        this.showPagination = this.totalPages > 1; // 是否显示分页
      });
    },
    //点击分页按钮
    onPageChange(page) {
      this.currentPage = page;
      this.getNews(this.currentPage)
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    //跳转
    details(id){
      this.$router.push('/NewsDetails/' + id)
    },
  }
}
</script>

<style scoped>

</style>